import axios from 'axios'
import { getToken } from './auth'
import { ElMessage } from 'element-plus' // 引入消息插件
import router from '../router' // 用于处理重定向

// 创建 axios 实例
const service = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL, // 基础URL
  timeout: 5000 // 请求超时时间
})

// 请求拦截器：在发送请求前可以做一些处理
service.interceptors.request.use(
  config => {
    // 如果有 token，就在请求头中添加 token
    if (getToken()) {
      config.headers['X-Token'] = getToken()
    }
    return config
  },
  error => {
    // 处理请求错误
    console.error('请求错误:', error)
    return Promise.reject(error)
  }
)

// 响应拦截器：用于统一处理响应和错误
service.interceptors.response.use(
  response => {
    const res = response.data // 简化获取的数据内容

    // 处理后端自定义的 code, 20000 代表请求成功
    if (res.code !== 20000) {
      // 如果返回的结果 code 不是 20000，认为是错误
      ElMessage.error(res.message || '操作失败') // 显示错误消息

      // 抛出错误，以便调用方捕获
      return Promise.reject(new Error(res.message || 'Error'))
    } else {
      return res // 返回正确响应数据
    }
  },
  error => {
    // 处理 HTTP 错误响应
    if (error.response) {
      const status = error.response.status
      if (status === 401) {
        // 未授权或登录过期时，重定向到登录页
        ElMessage.error('认证失败，请重新登录')
        router.push('/login')
      } else {
        // 非 401 错误，显示通用错误消息
        ElMessage.error(error.message || '请求失败，请稍后重试')
      }
    } else {
      // 处理没有 response 的情况下（如网络错误等）
      ElMessage.error('网络错误或服务器无响应')
    }

    // 抛出错误，供调用处进一步处理
    return Promise.reject(error)
  }
)

export default service