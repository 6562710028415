<template>
  <el-container class="gpt-chat-container">
    <el-header class="gpt-chat-header">
      <h2>AI 助手对话</h2>
      <div>
        <el-button text @click="clearChat">
          <el-icon><Delete /></el-icon>清空对话
        </el-button>
        <el-button text @click="$router.push('/')">
          <el-icon><Back /></el-icon>返回主页
        </el-button>
      </div>
    </el-header>
    <el-main class="gpt-chat-main">
      <div class="chat-messages" ref="chatContainer">
        <div v-for="(message, index) in chatMessages" :key="index" :class="['message', message.role]">
          <el-avatar :size="40" :src="message.role === 'user' ? studentInfo.avatarUrl : 'https://example.com/ai-avatar.png'"></el-avatar>
          <div class="message-content">{{ message.content }}</div>
        </div>
      </div>
    </el-main>
    <el-footer class="gpt-chat-footer">
      <el-input
        v-model="userInput"
        placeholder="输入您的问题..."
        @keyup.enter="sendMessage"
      >
        <template #append>
          <el-button type="primary" @click="sendMessage" :loading="isLoading">发送</el-button>
        </template>
      </el-input>
      <div class="usage-info">剩余使用次数: {{ remainingUsage }}</div>
    </el-footer>
  </el-container>
</template>

<script setup>
import { ref, reactive, onMounted, nextTick } from 'vue'
import { ElMessage } from 'element-plus'
import { Delete, Back } from '@element-plus/icons-vue'
import { getStudentInfo } from '../utils/auth'
import request from '../utils/request'

const chatMessages = ref([])
const userInput = ref('')
const isLoading = ref(false)
const remainingUsage = ref(0)
const chatContainer = ref(null)

const studentInfo = reactive({
  studentId: '',
  name: '',
  className: '',
  avatarUrl: ''
})

const sendMessage = async () => {
  if (!userInput.value.trim()) return

  const userMessage = { role: 'user', content: userInput.value }
  chatMessages.value.push(userMessage)
  userInput.value = ''

  isLoading.value = true

  try {
    const response = await request.post('/student/gpt4', { prompt: userMessage.content })
    const aiMessage = { role: 'assistant', content: response.data.response }
    chatMessages.value.push(aiMessage)
    remainingUsage.value = response.data.remainingUsage
  } catch (error) {
    console.error('Failed to get AI response:', error)
    ElMessage.error('获取AI回复失败')
  } finally {
    isLoading.value = false
    await nextTick()
    scrollToBottom()
  }
}

const clearChat = () => {
  chatMessages.value = []
}

const scrollToBottom = () => {
  if (chatContainer.value) {
    chatContainer.value.scrollTop = chatContainer.value.scrollHeight
  }
}

const fetchRemainingUsage = async () => {
  try {
    const response = await request.get('/student/ai-usage')
    remainingUsage.value = response.data.remainingUsage
  } catch (error) {
    console.error('Failed to fetch remaining usage:', error)
    ElMessage.error('获取剩余使用次数失败')
  }
}

onMounted(() => {
  const info = getStudentInfo()
  if (info) {
    Object.assign(studentInfo, info)
  }
  fetchRemainingUsage()
})
</script>

<style scoped>
.gpt-chat-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.gpt-chat-header {
  background-color: var(--el-bg-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  box-shadow: var(--el-box-shadow-light);
}

.gpt-chat-main {
  flex-grow: 1;
  overflow-y: auto;
  padding: 20px;
}

.gpt-chat-footer {
  background-color: var(--el-bg-color);
  padding: 20px;
  box-shadow: var(--el-box-shadow-light);
}

.chat-messages {
  display: flex;
  flex-direction: column;
}

.message {
  display: flex;
  margin-bottom: 20px;
  align-items: flex-start;
}

.message.user {
  flex-direction: row-reverse;
}

.message-content {
  background-color: var(--el-bg-color-page);
  padding: 10px 15px;
  border-radius: 15px;
  max-width: 70%;
  margin: 0 10px;
}

.message.user .message-content {
  background-color: var(--el-color-primary-light-9);
}

.usage-info {
  text-align: right;
  margin-top: 10px;
  color: var(--el-text-color-secondary);
}

@media screen and (max-width: 768px) {
  .gpt-chat-header, .gpt-chat-footer {
    padding: 10px;
  }

  .gpt-chat-main {
    padding: 10px;
  }

  .message-content {
    max-width: 80%;
  }
}
</style>