<template>
  <el-container class="approval-request-container">
    <el-header class="page-header">
      <h2>学分审批请求</h2>
    </el-header>
    <el-main>
      <el-card class="approval-request-card">
        <h3>提交学分审批请求</h3>
        <el-form :model="approvalForm" :rules="rules" ref="approvalFormRef" label-width="120px">
          <el-form-item label="活动名称" prop="activityName">
            <el-input v-model="approvalForm.activityName"></el-input>
          </el-form-item>
          <el-form-item label="学分数量" prop="creditAmount">
            <el-input-number v-model="approvalForm.creditAmount" :min="0" :max="10" :step="0.5"></el-input-number>
          </el-form-item>
          <el-form-item label="学分类型" prop="creditType">
            <el-select v-model="approvalForm.creditType">
              <el-option label="思想引领" value="思想引领"></el-option>
              <el-option label="社会实践" value="社会实践"></el-option>
              <el-option label="志愿服务" value="志愿服务"></el-option>
              <el-option label="学科竞赛" value="学科竞赛"></el-option>
              <el-option label="技能培训" value="技能培训"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="证明图片" prop="proofImage">
            <el-upload
              class="upload-demo"
              action="#"
              :http-request="handleUpload"
              :show-file-list="false"
              :before-upload="beforeUpload"
            >
              <el-button type="primary">选择图片</el-button>
            </el-upload>
            <img v-if="imageUrl" :src="imageUrl" class="preview-image" />
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitApprovalRequest">提交审批请求</el-button>
          </el-form-item>
        </el-form>
      </el-card>

      <el-card class="approval-requests-card">
        <h3>我的审批请求</h3>
        <el-table :data="approvalRequests" style="width: 100%">
          <el-table-column prop="activityName" label="活动名称"></el-table-column>
          <el-table-column prop="creditAmount" label="学分数量"></el-table-column>
          <el-table-column prop="creditType" label="学分类型"></el-table-column>
          <el-table-column prop="status" label="状态">
            <template #default="scope">
              <el-tag :type="getStatusType(scope.row.status)">{{ getStatusText(scope.row.status) }}</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="rejectReason" label="驳回原因">
            <template #default="scope">
              {{ scope.row.rejectReason || '-' }}
            </template>
          </el-table-column>
          <el-table-column label="操作" width="120">
            <template #default="scope">
              <el-button
                v-if="scope.row.status === 'a'"
                type="danger"
                size="small"
                @click="withdrawRequest(scope.row.id)"
              >
                撤回
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-size="pageSize"
          :total="total"
          layout="total, prev, pager, next"
        >
        </el-pagination>
      </el-card>
    </el-main>
  </el-container>
</template>

<script setup>
import { ref, reactive, onMounted } from 'vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import { approvalRequestService } from '../services/approvalRequestService'

const approvalFormRef = ref(null)
const approvalForm = reactive({
  activityName: '',
  creditAmount: 0,
  creditType: '',
  proofImage: null
})

const rules = {
  activityName: [{ required: true, message: '请输入活动名称', trigger: 'blur' }],
  creditAmount: [{ required: true, message: '请输入学分数量', trigger: 'blur' }],
  creditType: [{ required: true, message: '请选择学分类型', trigger: 'change' }],
  proofImage: [{ required: true, message: '请上传证明图片', trigger: 'change' }]
}

const imageUrl = ref('')
const approvalRequests = ref([])
const currentPage = ref(1)
const pageSize = ref(20)
const total = ref(0)

const handleUpload = async (options) => {
  approvalForm.proofImage = options.file
  imageUrl.value = URL.createObjectURL(options.file)
}

const beforeUpload = (file) => {
  const isImage = file.type.startsWith('image/')
  if (!isImage) {
    ElMessage.error('请上传图片文件')
  }
  return isImage
}

const submitApprovalRequest = async () => {
  if (!approvalFormRef.value) return

  await approvalFormRef.value.validate(async (valid) => {
    if (valid) {
      try {
        const formData = new FormData()
        formData.append('activityName', approvalForm.activityName)
        formData.append('creditAmount', approvalForm.creditAmount)
        formData.append('creditType', approvalForm.creditType)
        formData.append('proofImage', approvalForm.proofImage)

        const response = await approvalRequestService.submitApprovalRequest(formData)

        ElMessage.success(response.message || '审批请求提交成功')
        fetchApprovalRequests()
        approvalFormRef.value.resetFields()
        imageUrl.value = ''
      } catch (error) {
        console.error('Error submitting approval request:', error)
        ElMessage.error('服务器错误，请稍后重试')
      }
    }
  })
}

const fetchApprovalRequests = async () => {
  try {
    const response = await approvalRequestService.getApprovalRequests(currentPage.value, pageSize.value)
    approvalRequests.value = response.data.items
    total.value = response.data.total
  } catch (error) {
    console.error('Error fetching approval requests:', error)
    ElMessage.error('获取审批请求失败')
  }
}

const handleCurrentChange = (val) => {
  currentPage.value = val
  fetchApprovalRequests()
}

const getStatusType = (status) => {
  switch (status) {
    case 'a': return 'warning'
    case 'b': return 'success'
    case 'c': return 'danger'
    default: return 'info'
  }
}

const getStatusText = (status) => {
  switch (status) {
    case 'a': return '待审批'
    case 'b': return '已通过'
    case 'c': return '已驳回'
    default: return '未知状态'
  }
}

const withdrawRequest = async (id) => {
  try {
    await ElMessageBox.confirm('确定要撤回这个审批请求吗？', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    })

    const response = await approvalRequestService.withdrawApprovalRequest(id)
    ElMessage.success(response.message || '审批请求已成功撤回')
    fetchApprovalRequests()
  } catch (error) {
    if (error !== 'cancel') {
      console.error('Error withdrawing approval request:', error)
      ElMessage.error('撤回请求失败，请稍后重试')
    }
  }
}

onMounted(() => {
  fetchApprovalRequests()
})
</script>

<style scoped>
.approval-request-container {
  height: 100vh;
}

.page-header {
  background-color: var(--el-bg-color);
  box-shadow: var(--el-box-shadow-light);
  display: flex;
  align-items: center;
  padding: 0 20px;
}

.approval-request-card, .approval-requests-card {
  margin-bottom: 20px;
}

.preview-image {
  max-width: 200px;
  max-height: 200px;
  margin-top: 10px;
}

@media screen and (max-width: 768px) {
  .el-form-item {
    margin-bottom: 18px;
  }

  .el-form-item__label {
    padding: 0 0 8px;
    line-height: 1.5;
  }

  .el-form-item__content {
    line-height: 1.5;
  }

  .el-input-number {
    width: 100%;
  }
}
</style>