<template>
  <el-container class="dashboard-container">
    <el-aside :width="isCollapse ? '64px' : '200px'" class="sidebar" :class="{ 'expanded': !isCollapse }">
      <div class="sidebar-toggle" @click="toggleSidebar">
        <el-icon :size="24">
          <Fold v-if="!isCollapse" />
          <Expand v-else />
        </el-icon>
      </div>
      <div v-if="!isCollapse" class="user-info">
        <el-avatar :size="64" :src="studentInfo.avatarUrl || 'https://cunchu-1252452741.cos.ap-tokyo.myqcloud.com/xiaoccmw%2Fremove.photos-removed-background.png'"></el-avatar>
        <h3>{{ studentInfo.name }}</h3>
        <p>{{ studentInfo.className }}</p>
      </div>
      <div v-if="!isCollapse" class="logo">
        <el-icon><Opportunity /></el-icon>
        <span>学生门户</span>
      </div>
      <el-menu :default-active="activeIndex" class="sidebar-menu" :collapse="isCollapse">
        <el-menu-item index="1">
          <el-icon><DataLine /></el-icon>
          <template #title>学分明细</template>
        </el-menu-item>
        <el-menu-item index="2" @click="$router.push('/gpt-chat')">
          <el-icon><ChatDotRound /></el-icon>
          <template #title>AI 助手</template>
        </el-menu-item>
        <el-menu-item index="3" @click="$router.push('/change-password')">
          <el-icon><Lock /></el-icon>
          <template #title>修改密码</template>
        </el-menu-item>
        <el-menu-item index="4" @click="logout">
          <el-icon><SwitchButton /></el-icon>
          <template #title>退出登录</template>
        </el-menu-item>
        <el-menu-item index="5" @click="$router.push('/approval-request')">
          <el-icon><Document /></el-icon>
          <template #title>学分审批请求</template>
        </el-menu-item>
      </el-menu>
    </el-aside>
    <el-container>
      <el-header class="dashboard-header">
        <h2>学分明细</h2>
        <div>
          <el-button text @click="fetchCreditDetails">
            <el-icon><Refresh /></el-icon>刷新
          </el-button>
          <el-button text @click="showAuthorInfo">
            <el-icon><InfoFilled /></el-icon>关于作者
          </el-button>
        </div>
      </el-header>
      <el-main>
        <el-row :gutter="20">
          <el-col :xs="24" :sm="12" :md="8">
            <el-card class="credit-card">
              <div class="credit-total">
                <span>总学分</span>
                <h1>{{ creditSummary.totalCredit }}</h1>
              </div>
            </el-card>
          </el-col>
          <el-col :xs="24" :sm="12" :md="16">
            <el-card class="credit-types-card">
              <h3>学分类型明细</h3>
              <el-table :data="creditTypeData" style="width: 100%">
                <el-table-column prop="type" label="类型"></el-table-column>
                <el-table-column prop="credit" label="学分"></el-table-column>
              </el-table>
            </el-card>
          </el-col>
        </el-row>
        <el-card class="credit-chart-card">
          <h3>学分占比</h3>
          <dashboard-chart :credit-data="creditSummary.creditTypes" />
        </el-card>
        <el-card class="credit-details-card">
          <h3>学分明细</h3>
          <el-table :data="creditDetails" style="width: 100%">
            <el-table-column prop="activityTitle" label="活动名称"></el-table-column>
            <el-table-column prop="activityDate" label="日期"></el-table-column>
            <el-table-column prop="activityLocation" label="地点"></el-table-column>
            <el-table-column prop="creditType" label="学分类型"></el-table-column>
            <el-table-column prop="score" label="学分"></el-table-column>
          </el-table>
        </el-card>
      </el-main>
      <el-footer class="dashboard-footer">
        <p>© {{ currentYear }} 学分管理系统 | Powered by {{ companyName }}</p>
      </el-footer>
    </el-container>

    <el-dialog
      v-model="authorDialogVisible"
      title="关于作者"
      width="30%">
      <div class="author-info">
        <el-avatar :size="100" src="https://example.com/author-avatar.jpg"></el-avatar>
        <h3>{{ authorInfo.name }}</h3>
        <p>{{ authorInfo.title }}</p>
        <p>{{ authorInfo.bio }}</p>
        <div class="author-skills">
          <el-tag v-for="skill in authorInfo.skills" :key="skill" class="skill-tag">{{ skill }}</el-tag>
        </div>
        <p><el-icon><Message /></el-icon> {{ authorInfo.email }}</p>
        <p><el-icon><Phone /></el-icon> {{ authorInfo.phone }}</p>
      </div>
    </el-dialog>
  </el-container>
</template>

<script setup>
import { ref, reactive, computed, onMounted, onUnmounted } from 'vue'
import { useRouter } from 'vue-router'
import { ElMessage } from 'element-plus'
import {
  Refresh, InfoFilled, Message, Phone, Opportunity, DataLine, Lock,
  SwitchButton, Fold, Expand
  , ChatDotRound
} from '@element-plus/icons-vue'
import { removeToken, getStudentInfo } from '../utils/auth'
import request from '../utils/request'
import DashboardChart from './DashboardChart.vue'

const router = useRouter()

const authorDialogVisible = ref(false)
const studentInfo = reactive({
  studentId: '',
  name: '',
  className: ''
})
const creditSummary = reactive({ totalCredit: 0, creditTypes: {} })
const creditDetails = ref([])
const isCollapse = ref(false)
const activeIndex = ref('1')

const authorInfo = reactive({
  name: '张鹏翔',
  title: '励志当英语老师的英专生',
  bio: '追求好好学习天天向上',
  skills: ['Vue.js', 'JavaScript', 'HTML5', 'Node.js', 'English'],
  email: 'mail@hbfu.org',
  phone: '+86 从上面邮箱联系我'
})

const creditTypeData = computed(() =>
  Object.entries(creditSummary.creditTypes).map(([type, credit]) => ({ type, credit }))
)

const showAuthorInfo = () => {
  authorDialogVisible.value = true
}

const fetchCreditDetails = async () => {
  try {
    const response = await request.get('/studentser/credit-details')
    Object.assign(creditSummary, response.data.creditSummary)
    creditDetails.value = response.data.creditDetails
  } catch (error) {
    console.error('Failed to fetch credit details:', error)
    ElMessage.error('获取学分明细失败')
  }
}

const logout = () => {
  removeToken()
  localStorage.removeItem('studentInfo')
  router.push('/login')
}

const toggleSidebar = () => {
  isCollapse.value = !isCollapse.value
}

const checkScreenSize = () => {
  isCollapse.value = window.innerWidth <= 768
}

onMounted(() => {
  const info = getStudentInfo()
  if (info) {
    Object.assign(studentInfo, info)
  }
  fetchCreditDetails()
  checkScreenSize()
  window.addEventListener('resize', checkScreenSize)
})

onUnmounted(() => {
  window.removeEventListener('resize', checkScreenSize)
})
const currentYear = ref(new Date().getFullYear())
const companyName = ref('21商二张鹏翔')
</script>

<style scoped>
.dashboard-container {
  height: 100vh;
  overflow-x: hidden;
  display: flex;
}
.sidebar {
  background-color: var(--el-bg-color);
  box-shadow: var(--el-box-shadow-light);
  transition: width 0.3s, max-height 0.3s;
}
.sidebar-toggle {
  padding: 10px;
  text-align: center;
  cursor: pointer;
}
.user-info {
  padding: 20px;
  text-align: center;
  border-bottom: 1px solid var(--el-border-color-light);
}
.user-info h3 {
  margin: 10px 0 5px;
  color: var(--el-text-color-primary);
}
.user-info p {
  margin: 0;
  font-size: 0.9em;
  color: var(--el-text-color-secondary);
}
.logo {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  color: var(--el-color-primary);
}
.logo .el-icon {
  margin-right: 10px;
}
.sidebar-menu {
  border-right: none;
}
.dashboard-header {
  background-color: var(--el-bg-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  box-shadow: var(--el-box-shadow-light);
}
.dashboard-footer {
  background-color: var(--el-bg-color-page);
  color: var(--el-text-color-secondary);
  text-align: center;
  padding: 20px;
  font-size: 14px;
  border-top: 1px solid var(--el-border-color-lighter);
}
.credit-card, .credit-types-card, .credit-details-card, .credit-chart-card {
  margin-bottom: 20px;
  border-radius: 8px;
  box-shadow: var(--el-box-shadow-light);
}
.credit-total {
  text-align: center;
  padding: 20px 0;
}
.credit-total span {
  font-size: 1.2rem;
  color: var(--el-text-color-regular);
}
.credit-total h1 {
  font-size: 3rem;
  color: var(--el-color-primary);
  margin: 10px 0;
}
.author-info {
  text-align: center;
}
.author-info h3 {
  margin: 10px 0 5px;
  color: var(--el-color-primary);
}
.author-info p {
  margin: 5px 0;
}
.author-skills {
  margin: 10px 0;
}
.skill-tag {
  margin: 0 5px 5px 0;
}

@media screen and (max-width: 768px) {
  .dashboard-container {
    flex-direction: column;
  }
  .sidebar {
    width: 100% !important;
    max-height: 60px;
    overflow: hidden;
  }
  .sidebar.expanded {
    max-height: 100vh;
  }
  .el-main {
    padding: 10px;
  }
  .dashboard-header {
    padding: 0 10px;
  }
  .credit-card, .credit-types-card, .credit-details-card, .credit-chart-card {
    margin-bottom: 10px;
  }
  .el-table {
    font-size: 12px;
  }
  .el-table .cell {
    padding-left: 5px;
    padding-right: 5px;
  }
}
</style>