import Cookies from 'js-cookie'

const TokenKey = 'student_token'

export function getToken () {
  return Cookies.get(TokenKey)
}

export function setToken (token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken () {
  return Cookies.remove(TokenKey)
}

export function setStudentInfo(studentInfo) {
  localStorage.setItem('studentInfo', JSON.stringify(studentInfo))
}

export function getStudentInfo() {
  const studentInfo = localStorage.getItem('studentInfo')
  return studentInfo ? JSON.parse(studentInfo) : null
}