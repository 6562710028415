import { createRouter, createWebHistory } from 'vue-router'
import Login from '../components/Login.vue'
import Dashboard from '../components/Dashboard.vue'
import ChangePassword from '../components/ChangePassword.vue'
import { getToken } from '../utils/auth'
import GptChat from '../components/GptChat.vue'
import ApprovalRequestPage from '../components/ApprovalRequestPage.vue'

const routes = [
  { path: '/login', component: Login },
  {
    path: '/',
    component: Dashboard,
    meta: { requiresAuth: true }
  },
  {
    path: '/change-password',
    component: ChangePassword,
    meta: { requiresAuth: true }
  },
  {
    path: '/gpt-chat',
    name: 'GptChat',
    component: GptChat,
    meta: { requiresAuth: true }
  },
  {
    path: '/approval-request',
    name: 'ApprovalRequest',
    component: ApprovalRequestPage,
    meta: { requiresAuth: true }
  }

]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!getToken()) {
      next('/login')
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router