<template>
  <div class="login-container">
    <el-card class="login-card">
      <div class="login-header">
        <h2>学生登录</h2>
        <div class="logo">
          <i class="el-icon-s-opportunity"></i>
        </div>
      </div>
      <el-form :model="loginForm" :rules="loginRules" ref="loginForm">
        <el-form-item prop="studentId">
          <el-input v-model="loginForm.studentId" placeholder="学号">
            <i slot="prefix" class="el-input__icon el-icon-user"></i>
          </el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input v-model="loginForm.password" type="password" placeholder="密码">
            <i slot="prefix" class="el-input__icon el-icon-lock"></i>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleLogin" :loading="loading" class="login-button">登录</el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>


<script>
import { setToken, setStudentInfo } from '../utils/auth'
import request from '../utils/request'

export default {
  name: 'LoginView',
  data() {
    return {
      loginForm: {
        studentId: '',
        password: ''
      },
      loginRules: {
        studentId: [{ required: true, message: '请输入学号', trigger: 'blur' }],
        password: [{ required: true, message: '请输入密码', trigger: 'blur' }]
      },
      loading: false
    }
  },
  methods: {
    handleLogin() {
      this.$refs.loginForm.validate(async valid => {
        if (valid) {
          this.loading = true
          try {
            const response = await request.post('/student/login', this.loginForm)
            setToken(response.data.token)

            // 保存学生信息
            if (response.data.studentInfo) {
              setStudentInfo(response.data.studentInfo)
              console.log('Saved student info:', response.data.studentInfo) // 调试日志
            } else {
              console.warn('Student info not found in response') // 调试日志
            }

            this.$router.push('/')
          } catch (error) {
            console.error('Login failed:', error)
            this.$message.error('登录失败，请检查学号和密码')
          } finally {
            this.loading = false
          }
        }
      })
    }
  }
}
</script>

<style scoped>
.login-container {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(135deg, var(--primary-color), var(--secondary-color));
}
.login-card {
  width: 350px;
  border-radius: 8px;
  box-shadow: var(--shadow);
  background-color: var(--card-background);
}
.login-header {
  text-align: center;
  margin-bottom: 2rem;
}
.logo {
  font-size: 4rem;
  color: var(--primary-color);
  margin-bottom: 1rem;
}
.login-button {
  width: 100%;
}
</style>