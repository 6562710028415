<template>
  <el-container class="change-password-container">
    <el-aside width="200px" class="sidebar">
      <div class="logo">
        <i class="el-icon-s-opportunity"></i>
        <span>学生门户</span>
      </div>
      <el-menu default-active="2" class="sidebar-menu">
        <el-menu-item index="1" @click="$router.push('/')">
          <i class="el-icon-s-data"></i>
          <span>学分明细</span>
        </el-menu-item>
        <el-menu-item index="2">
          <i class="el-icon-lock"></i>
          <span>修改密码</span>
        </el-menu-item>
        <el-menu-item index="3" @click="logout">
          <i class="el-icon-switch-button"></i>
          <span>退出登录</span>
        </el-menu-item>
      </el-menu>
    </el-aside>
    <el-container>
      <el-header class="dashboard-header">
        <h2>修改密码</h2>
      </el-header>
      <el-main>
        <el-card class="password-card">
          <el-form :model="passwordForm" :rules="passwordRules" ref="passwordForm" label-width="100px">
            <el-form-item label="旧密码" prop="oldPassword">
              <el-input v-model="passwordForm.oldPassword" type="password">
                <i slot="prefix" class="el-input__icon el-icon-lock"></i>
              </el-input>
            </el-form-item>
            <el-form-item label="新密码" prop="newPassword">
              <el-input v-model="passwordForm.newPassword" type="password">
                <i slot="prefix" class="el-input__icon el-icon-key"></i>
              </el-input>
            </el-form-item>
            <el-form-item label="确认新密码" prop="confirmPassword">
              <el-input v-model="passwordForm.confirmPassword" type="password">
                <i slot="prefix" class="el-input__icon el-icon-check"></i>
              </el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="changePassword" :loading="loading" class="change-password-button">
                修改密码
              </el-button>
            </el-form-item>
          </el-form>
        </el-card>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import request from '../utils/request'
import { removeToken } from '../utils/auth'

export default {
  name: 'ChangePasswordView',
  data() {
    const validateConfirmPassword = (rule, value, callback) => {
      if (value !== this.passwordForm.newPassword) {
        callback(new Error('两次输入的密码不一致'))
      } else {
        callback()
      }
    }
    return {
      passwordForm: {
        oldPassword: '',
        newPassword: '',
        confirmPassword: ''
      },
      passwordRules: {
        oldPassword: [{ required: true, message: '请输入旧密码', trigger: 'blur' }],
        newPassword: [
          { required: true, message: '请输入新密码', trigger: 'blur' },
          { min: 6, message: '密码长度至少为6个字符', trigger: 'blur' }
        ],
        confirmPassword: [
          { required: true, message: '请确认新密码', trigger: 'blur' },
          { validator: validateConfirmPassword, trigger: 'blur' }
        ]
      },
      loading: false
    }
  },
  methods: {
    async changePassword() {
      try {
        await this.$refs.passwordForm.validate()
        this.loading = true
        await request.put('/student/change-password', {
          oldPassword: this.passwordForm.oldPassword,
          newPassword: this.passwordForm.newPassword
        })
        this.$message.success('密码修改成功')
        this.$router.push('/')
      } catch (error) {
        console.error('Failed to change password:', error)
        this.$message.error('密码修改失败，请检查旧密码是否正确')
      } finally {
        this.loading = false
      }
    },
    logout() {
      removeToken()
      this.$router.push('/login')
    }
  }
}
</script>

<style scoped>
.change-password-container {
  height: 100vh;
}
.sidebar {
  background-color: var(--card-background);
  box-shadow: var(--shadow);
}
.logo {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  color: var(--primary-color);
}
.logo i {
  margin-right: 10px;
}
.sidebar-menu {
  border-right: none;
}
.dashboard-header {
  background-color: var(--card-background);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  box-shadow: var(--shadow);
}
.password-card {
  max-width: 500px;
  margin: 20px auto;
  border-radius: 8px;
  box-shadow: var(--shadow);
}
.change-password-button {
  width: 100%;
}
</style>
