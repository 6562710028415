<template>
  <div class="chart-container">
    <canvas ref="chartCanvas"></canvas>
  </div>
</template>

<script>
import { Chart, ArcElement, Tooltip, Legend, Title, PieController } from 'chart.js'

Chart.register(ArcElement, Tooltip, Legend, Title, PieController)

export default {
  name: 'DashboardChart',
  props: {
    creditData: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      chart: null
    }
  },
  mounted() {
    this.renderChart()
  },
  methods: {
    renderChart() {
      if (this.chart) {
        this.chart.destroy()
      }

      const ctx = this.$refs.chartCanvas.getContext('2d')
      const labels = Object.keys(this.creditData)
      const data = Object.values(this.creditData)

      this.chart = new Chart(ctx, {
        type: 'pie',
        data: {
          labels: labels,
          datasets: [{
            data: data,
            backgroundColor: [
              'rgba(255, 99, 132, 0.8)',
              'rgba(54, 162, 235, 0.8)',
              'rgba(255, 206, 86, 0.8)',
              'rgba(75, 192, 192, 0.8)',
              'rgba(153, 102, 255, 0.8)',
              'rgba(255, 159, 64, 0.8)'
            ],
            borderColor: [
              'rgba(255, 99, 132, 1)',
              'rgba(54, 162, 235, 1)',
              'rgba(255, 206, 86, 1)',
              'rgba(75, 192, 192, 1)',
              'rgba(153, 102, 255, 1)',
              'rgba(255, 159, 64, 1)'
            ],
            borderWidth: 1
          }]
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              position: 'top'
            },
            title: {
              display: true,
              text: '学分类型占比'
            },
            tooltip: {
              callbacks: {
                label(context) {
                  let label = context.label || ''
                  if (label) {
                    label += ': '
                  }
                  if (context.parsed !== null) {
                    label += context.parsed.toFixed(2) + ' 学分'
                  }
                  return label
                }
              }
            }
          }
        }
      })
    }
  },
  watch: {
    creditData: {
      handler() {
        this.renderChart()
      },
      deep: true
    }
  },
  beforeUnmount() {
    if (this.chart) {
      this.chart.destroy()
    }
  }
}
</script>

<style scoped>
.chart-container {
  height: 300px;
}
</style>