import request from '../utils/request'

export const approvalRequestService = {
  submitApprovalRequest(formData) {
    return request.post('/student/approval-request', formData, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  },

  getApprovalRequests(page, limit) {
    return request.get('/student/approval-requests', {
      params: { page, limit }
    })
  },

  withdrawApprovalRequest(id) {
    return request.delete(`/student/approval-request/${id}`)
  }
}